<template>
  <router-view> </router-view>
</template>

<style lang="scss" scoped>
/* fade-transform */
// .slide-transform-leave-active,
// .slide-transform-enter-active {
//   transition: all 0.5s;
// }

.slide-transform-enter {
  transform: translate3d(0, 0, 0);
}
.slide-transform-enter-active {
  transform: translate3d(100%, 0, 0);
  transition: all 0.3s;
}
.slide-transform-leave-active {
  transform: translate3d(-100%, 0, 0);
  transition: all 0.3s;
}

.slide-transform-leave-to {
  transform: translate3d(0, 0, 0);
}
</style>
