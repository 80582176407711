import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/list/1",
  },
  {
    path: "/list/:aid",
    name: "list",
    component: () => import("../views/index/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/productDto",
    name: "productDto",
    component: () => import("../views/productDto/productDto.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/user.vue"),
  },
  {
    path: "/address",
    name: "address",
    component: () => import("../views/address/address.vue"),
  },
  {
    path: "/editAddress",
    name: "editAddress",
    component: () => import("../views/address/editAddress.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/help.vue"),
  },
  {
    path: "/subOrder",
    name: "subOrder",
    component: () => import("../views/subOrder/subOrder.vue"),
  },
  {
    path: "/virtualOrder",
    name: "virtualOrder",
    component: () => import("../views/virtualOrder/virtualOrder.vue"),
  },
  {
    path: "/record",
    name: "record",
    component: () => import("../views/record/record.vue"),
  },
  {
    path: "/orderDto",
    name: "orderDto",
    component: () => import("../views/orderDto/orderDto.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
