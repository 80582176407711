/*
 * @Author: yang: 1327462182@qq.com
 * @Date:
 * @Last Modified by: yang: 1327462182@qq.com
 * @Last Modified time:
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import Vant from "vant";
import "vant/lib/index.css";

const app = createApp(App);

app.use(store).use(router).use(Vant).mount("#app");
